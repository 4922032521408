@font-face {
  font-family: Scriptina;
  src: url('./scriptina.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* This ensures that we have the Scriptina font loaded before printing! */

.resume::after {
  content: " ";
  font-size: 0;
  font-family: Scriptina, serif;
}
